'use client';
import { useNotification } from '@/hooks/useNotification';
import { listNotifications } from '@/lib/listNotifications';
import { IconBell } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import Link from 'next/link';
import React, { useState } from 'react';
import {
  Badge,
  Button,
  ButtonProps,
  DataTableTitle,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  cn,
} from 'ui';
import { NotificationList } from './NotificationList';
import { WaitForQuery } from './WaitForQuery';

const BellButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    notificationsCount?: number;
  }
>(({ className, notificationsCount, ...props }, ref) => {
  return (
    <Button
      {...props}
      variant={'ghost'}
      className={cn('relative', className)}
      ref={ref}
    >
      <IconBell />
      <div className='absolute right-0 -top-2'>
        {!!notificationsCount && notificationsCount > 0 && (
          <Badge className='text-xs font-medium px-1'>
            {notificationsCount}
          </Badge>
        )}
      </div>
    </Button>
  );
});

export const NotificationsBell = () => {
  const query = useQuery({
    queryKey: [`notifications-unread`],
    queryFn: () =>
      listNotifications({
        page: 1,
        limit: 15,
        scope: 'unread',
      }),
  });

  const notification = useNotification();

  const [isNotificationOptsOpen, setIsNotificationOptsOpen] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <BellButton notificationsCount={query.data?.items?.length} />
      </PopoverTrigger>
      <PopoverContent
        align='end'
        side='bottom'
        className='w-[25rem] p-0 divide-y-[1px]'
      >
        <div className='flex flex-row w-full items-center justify-between p-4'>
          <DataTableTitle className='text-md'>Notificaciones</DataTableTitle>
          <Button
            disabled={!query.data?.items?.length}
            onClick={() => {
              if (!query.data?.items?.length) return [];

              notification.batchRead(query.refetch)(
                query.data.items.map((notification) => notification.id),
              );
            }}
            loading={notification.isMutatingLoading}
            size='xs'
            variant={'ghost'}
          >
            Marcar todo como leído
          </Button>
        </div>
        <ScrollArea
          orientation={'vertical'}
          className='h-[20rem]'
          viewportClassName={cn('[&>*]:h-full', {
            '!overflow-hidden': isNotificationOptsOpen,
          })}
          type={isNotificationOptsOpen ? 'scroll' : 'auto'}
        >
          <WaitForQuery query={query}>
            {({ items }) => (
              <NotificationList
                onNotificationOptsChange={setIsNotificationOptsOpen}
                items={items}
                onMarkAsRead={notification.batchRead(query.refetch)}
              />
            )}
          </WaitForQuery>
        </ScrollArea>
        <div className='w-full p-2 text-center'>
          <Link
            onClick={() => setOpen(false)}
            href='/notifications'
            className='text-sm'
          >
            Ver todas las notificaciones
          </Link>
        </div>
      </PopoverContent>
    </Popover>
  );
};
