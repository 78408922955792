import { httpClient } from '@/http/client';
import { ListCompanyLinkRequestQuery, ListCompanyLinkResponseQuery } from 'bff';

export const listCompanyLink = ({
  cookie,
  query,
}: {
  cookie?: string;
  query: ListCompanyLinkRequestQuery;
}): Promise<ListCompanyLinkResponseQuery> =>
  httpClient
    .headers(
      cookie
        ? {
            cookie,
          }
        : {},
    )
    .query(query)
    .get(`/v1/company-link`)
    .json();
