'use client';
import { ListCompanyEntrypointsResponseBody } from 'bff';
import React from 'react';

type Entrypoint = ListCompanyEntrypointsResponseBody['entrypoints'][number];

export interface EntrypointsContext {
  entrypoints: Entrypoint[];
}

const EntrypointsContext = React.createContext<EntrypointsContext | undefined>(
  undefined,
);

export const useEntrypoints = () => React.useContext(EntrypointsContext)!;

export const EntrypointsProvider = ({
  children,
  entrypoints,
}: {
  children: React.ReactNode;
  entrypoints: Entrypoint[];
}) => {
  return (
    <EntrypointsContext.Provider
      value={{
        entrypoints,
      }}
    >
      {children}
    </EntrypointsContext.Provider>
  );
};
