'use client';

import { executeJob } from '@/lib/executeJob';
import { unscheduleJob } from '@/lib/unscheduleJob';
import { IconInfoCircle } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { useReactTable } from '@tanstack/react-table';
import { JobUnion } from 'bff';
import { format } from 'date-fns';
import { useRouter } from 'next/navigation';
import { useCallback, useState } from 'react';
import { useApiError } from 'shared-components';
import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertTitle,
  Button,
  useToast,
} from 'ui';

export const CREATED_JOBS_QUERY_KEY = 'created-jobs';

const UnscheduleJobButton = ({ job }: { job: JobUnion }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const { handleError } = useApiError();

  const queryClient = useQueryClient();

  const { toast } = useToast();

  const onClick = useCallback(() => {
    setIsLoading(true);

    unscheduleJob({
      id: job.id,
    })
      .then(() => {
        toast({
          title: 'Programación cancelada',
          description: 'La programación se ha cancelado correctamente',
        });

        queryClient.invalidateQueries([CREATED_JOBS_QUERY_KEY]);

        setOpen(false);
      })
      .catch(handleError)
      .finally(() => setIsLoading(false));
  }, [job.id]);

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button loading={isLoading} size='sm'>
          Cancelar
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>¿Estas seguro?</AlertDialogTitle>
          <AlertDialogDescription>
            Perderás la programación del cambio de estado que estaba prevista
            para el {format(new Date(job.execute_at!), 'dd/MM/yyyy')}. Esta
            acción no se puede deshacer.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel asChild>
            <Button disabled={isLoading} variant={'outline'}>
              Cancelar
            </Button>
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button loading={isLoading} onClick={onClick}>
              Continuar
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const ExecuteJobButton = ({ job }: { job: JobUnion }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const { handleError } = useApiError();

  const queryClient = useQueryClient();

  const { toast } = useToast();

  const router = useRouter();

  const onClick = useCallback(() => {
    setIsLoading(true);

    executeJob({
      id: job.id,
    })
      .then(() => {
        window.location.reload();
      })
      .catch(handleError)
      .finally(() => setIsLoading(false));
  }, [job.id]);

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button loading={isLoading} size='sm'>
          Cambiar ahora
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>¿Estas seguro?</AlertDialogTitle>
          <AlertDialogDescription>
            Adelantaras la programación del cambio de estado de tu cuenta que
            estaba previsto para el{' '}
            {format(new Date(job.execute_at!), 'dd/MM/yyyy')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel asChild>
            <Button disabled={isLoading} variant={'outline'}>
              Cancelar
            </Button>
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button loading={isLoading} onClick={onClick}>
              Adelantar
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export const ScheduledJobsAlert = ({ job }: { job: JobUnion }) => {
  switch (job.type) {
    case 'non_operational_user':
      return (
        <Alert className='w-full flex flex-row items-center justify-between !px-0'>
          <div className='flex flex-row space-x-2 pl-4'>
            <IconInfoCircle className='h-4 w-4' />
            <div>
              <AlertTitle>Programación de estado no operativo</AlertTitle>
              <AlertDescription>
                <span>
                  Dejaras de estar operativo a partir del{' '}
                  {format(new Date(job.execute_at!), 'dd/MM/yyyy')} hasta el{' '}
                  {format(new Date(job.metadata.to!), 'dd/MM/yyyy')}
                </span>
              </AlertDescription>
            </div>
          </div>

          <div className='pr-4'>
            <UnscheduleJobButton job={job} />
          </div>
        </Alert>
      );

    case 'operational_user':
      return (
        <Alert className='w-full flex flex-row items-center justify-between !px-0'>
          <div className='flex flex-row space-x-2 pl-4'>
            <IconInfoCircle className='h-4 w-4' />
            <div>
              <AlertTitle>Programación de estado operativo</AlertTitle>
              <AlertDescription>
                <span>
                  A partir del {format(new Date(job.execute_at!), 'dd/MM/yyyy')}{' '}
                  estarás operativo y podrás recibir nuevos reclamos.
                </span>
              </AlertDescription>
            </div>
          </div>

          <div className='pr-4'>
            <ExecuteJobButton job={job} />
          </div>
        </Alert>
      );
  }
};
