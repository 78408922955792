import { UseQueryResult } from '@tanstack/react-query';
import { Skeleton } from 'ui';

export const SkeletonLoader = () => {
  return (
    <div className='space-y-2'>
      <Skeleton className='h-4 w-[250px]' />
      <Skeleton className='h-4 w-[200px]' />
    </div>
  );
};

export function WaitForQuery<T extends UseQueryResult>({
  query,
  children,
  loading,
}: {
  query: T;
  children: React.FC<Extract<T, { isSuccess: true }>['data']>;
  loading?: React.ReactNode;
}) {
  if (query.isLoading || query.error || !query.data) {
    if (loading) {
      return loading;
    }

    return <SkeletonLoader />;
  }

  return children(query.data);
}
