import { httpClient } from '@/http/client';
import { ListCreatedJobsRequestQuery, ListCreatedJobsResponseBody } from 'bff';
import qs from 'qs';

export const listCreatedJobs = async (
  query: ListCreatedJobsRequestQuery,
  cookie?: string,
): Promise<ListCreatedJobsResponseBody> =>
  httpClient
    .headers(
      cookie
        ? {
            cookie,
          }
        : {},
    )
    .query(qs.stringify(query))
    .get('/v1/job/me')
    .json();
