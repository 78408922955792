import { batchReadNotifications } from '@/lib/batchReadNotifications';
import { useCallback, useState } from 'react';

export const useNotification = () => {
  const [isMutatingLoading, setIsMutatingLoading] = useState(false);

  const batchRead = useCallback((onDone: () => void) => {
    return (notificationIds: number[]) => {
      setIsMutatingLoading(true);

      batchReadNotifications({
        ids: notificationIds,
      })
        .then(onDone)
        .finally(() => setIsMutatingLoading(false));
    };
  }, []);

  return {
    batchRead,
    isMutatingLoading,
  };
};
