import { Avatar, AvatarFallback, AvatarImage } from 'ui';
import React from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { User } from 'database';

export const UserAvatar = ({
  user,
}: {
  user: Pick<User, 'first_name' | 'last_name' | 'profile_photo'>;
}) => {
  return (
    <Avatar>
      <AvatarImage src={user.profile_photo!} />
      <AvatarFallback>
        {`${user.first_name.charAt(0)}${user.last_name.charAt(0)}`}
      </AvatarFallback>
    </Avatar>
  );
};

export const AuthAvatar = () => {
  const { user: data } = useAuth();

  return <UserAvatar user={data} />;
};
