'use client';

import { fetchUser } from '@/lib/fetchUser';
import { useQuery } from '@tanstack/react-query';
import { AuthInfoResponseBody, RetrieveCompanyStyleResponseQuery } from 'bff';
import { countryCurrencyList } from 'piramid-constants';
import { createContext, useContext, useMemo } from 'react';
import { SharedAuthProvider } from 'shared-components';

export const USER_QUERY_KEY = 'user';

export interface AuthContext {
  user: AuthInfoResponseBody;
}

const AuthContext = createContext<AuthContext | undefined>(undefined);

export const useAuth = () => useContext(AuthContext)!;

export const AuthProvider = ({
  children,
  user,
}: {
  children: React.ReactNode;
  user: AuthInfoResponseBody;
}) => {
  const { data } = useQuery({
    queryFn: () => fetchUser(),
    queryKey: [USER_QUERY_KEY],
    initialData: user,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  return (
    <SharedAuthProvider fetchUser={fetchUser} user={user}>
      <AuthContext.Provider
        value={{
          user: {
            ...data,
          },
        }}
      >
        {children}
      </AuthContext.Provider>
    </SharedAuthProvider>
  );
};
