'use client';

import { useAuth } from '@/contexts/AuthContext';
import { useTheme } from '@/contexts/ThemeContext';
import { Permission } from 'database';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import {
  PiramidLogo,
  Tabs,
  TabsList,
  TabsListProvider,
  TabsTrigger,
  cn,
} from 'ui';
import { UserOptions } from './UserOptions';
import { NotificationsBell } from './NotificationsBell';
import { CompanyLinks } from './CompanyLinks';
import { useEntrypoints } from '../contexts/EntrypointsContext';

export const DashboardLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user: data } = useAuth();

  const pathname = usePathname();

  const tabs = useMemo(() => {
    return [
      {
        label: 'Siniestros',
        href: '/sinister',
        show: (permissions: Permission[]) =>
          permissions.some((p) => p.type === 'can_read_sinister'),
      },
      {
        label: 'Denuncias',
        href: '/complaints',
        show: (permissions: Permission[]) =>
          permissions.some((p) => p.type === 'can_read_complaint') &&
          // TODO: redefine companyEntrypoints list
          data.companyEntrypoints?.some((e) => e === 'complaint'),
      },
      {
        label: 'Reclamos',
        href: '/claims',
        show: (permissions: Permission[]) =>
          permissions.some((p) => p.type === 'can_read_claim') &&
          data.companyEntrypoints?.some((e) => e === 'claim'),
      },
      {
        label: 'Estadísticas',
        href: '/stats/ops/claims',
        show: (permissions: Permission[]) =>
          permissions.some((p) => p.type === 'can_read_stats'),
      },
    ].filter((tab) => tab.show(data.role.permissions));
  }, []);

  const theme = useTheme();

  if (pathname.includes('create') && !pathname.includes('settings'))
    return <>{children}</>;

  return (
    <main>
      <nav
        className={cn(
          'w-full h-16 border-b-2 justify-between items-center flex px-10',
        )}
      >
        <div className='w-14'>
          {theme.logo ? (
            <img
              className='w-full h-full object-contain'
              src={theme.logo.url}
            />
          ) : (
            <div>
              <PiramidLogo
                size={'sm'}
                variant={theme.mode === 'dark' ? 'white' : 'black'}
              />
            </div>
          )}
        </div>

        <TabsListProvider variant={'underline'}>
          {/* 
          TODO: refactor how we calculate current tab from dashboard navbar layout
          so we can include a set of paths to be included as current
          tab and exclude some others 
          */}
          <Tabs
            value={pathname.includes('/stats') ? '/stats/ops/claims' : pathname}
            className='h-full'
          >
            <TabsList className='h-full'>
              {tabs.map(({ href, label }) => {
                return (
                  <TabsTrigger
                    asChild
                    className='h-full'
                    key={href}
                    value={href}
                  >
                    <Link href={href}>{label}</Link>
                  </TabsTrigger>
                );
              })}
            </TabsList>
          </Tabs>
        </TabsListProvider>
        <div className='flex flex-row items-center space-x-3'>
          {data.abilities.can_read_company_link && <CompanyLinks />}

          <NotificationsBell />
          <UserOptions />
        </div>
      </nav>
      <div className='p-14 flex items-center justify-center'>
        <div className='max-w-7xl w-full'>{children}</div>
      </div>
    </main>
  );
};
