'use client';
import {
  CREATED_JOBS_QUERY_KEY,
  ScheduledJobsAlert,
} from '@/components/ScheduledJobsAlert';
import { listCreatedJobs } from '@/lib/listCreatedJobs';
import { generateCreatedJobsFilters } from '@/utils/generate-created-jobs-filters';
import { useQuery } from '@tanstack/react-query';
import { ListCreatedJobsResponseBody } from 'bff';
import { startOfDay } from 'date-fns';
import { createContext, useContext } from 'react';

interface ScheduledJobsContext extends ListCreatedJobsResponseBody {}

export const ScheduledJobsContext = createContext<
  ScheduledJobsContext | undefined
>(undefined);

export const useScheduledJobs = () => useContext(ScheduledJobsContext)!;

export const ScheduledJobsProvider = ({
  children,
  initialData,
}: {
  children: React.ReactNode;
  initialData: ListCreatedJobsResponseBody;
}) => {
  const query = useQuery({
    queryKey: [CREATED_JOBS_QUERY_KEY],
    queryFn: () => listCreatedJobs(generateCreatedJobsFilters()),
    initialData,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  console.log('query', query.data.jobs);

  const [pendingJob] = query.data.jobs;

  return (
    <ScheduledJobsContext.Provider
      value={{
        jobs: query.data.jobs,
      }}
    >
      {pendingJob && <ScheduledJobsAlert job={pendingJob} />}
      {children}
    </ScheduledJobsContext.Provider>
  );
};
