'use client';
import { useAuth } from '@/contexts/AuthContext';
import { logoutUser } from '@/lib/logoutUser';
import { IconRun, IconSettings } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { useCallback, useState } from 'react';
import { useApiError } from 'shared-components';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'ui';
import { AuthAvatar } from './AuthAvatar';

const Logout = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { handleError } = useApiError();

  const router = useRouter();

  const queryClient = useQueryClient();

  const onLogout = useCallback(() => {
    setIsLoading(true);
    logoutUser()
      .then(() => {
        queryClient.clear();

        window.location.href = '/login';
      })
      .catch(handleError);
  }, []);

  return (
    <DropdownMenuItem onClick={onLogout}>
      <IconRun className='w-4 h-4 mr-2' />
      Cerrar sesión
    </DropdownMenuItem>
  );
};

export const UserOptions = () => {
  const { user } = useAuth();

  const router = useRouter();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <AuthAvatar />
      </DropdownMenuTrigger>
      <DropdownMenuContent align='end'>
        <DropdownMenuLabel>
          {user!.first_name} {user!.last_name}
          <br />
          <span className='text-zinc-500 font-light'>{user!.email}</span>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => router.push('/settings/account')}>
          <IconSettings className='w-4 h-4 mr-2' />
          Configuración
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <Logout />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
