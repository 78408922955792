import { useNotification } from '@/hooks/useNotification';
import { listNotifications } from '@/lib/listNotifications';
import { useQuery } from '@tanstack/react-query';
import Link from 'next/link';
import {
  Button,
  DataTable,
  DataTableTitle,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  cn,
  Badge,
  Avatar,
  AvatarImage,
  AvatarFallback,
  Separator,
  Input,
} from 'ui';
import { NotificationList } from './NotificationList';
import { WaitForQuery } from './WaitForQuery';
import { IconBolt } from '@tabler/icons-react';
import { listCompanyLink } from '@/lib/listCompanyLink';
import { CompanyLinkWithRelations } from 'bff';
import { redirect } from 'next/navigation';
import { useCallback, useState } from 'react';
import debounce from 'lodash.debounce';

const CompanyLinkList = ({ items }: { items: CompanyLinkWithRelations[] }) => {
  return (
    <div className='w-auto py-4'>
      <Separator />

      <div className='flex flex-col space-y-2'>
        {items.map((item) => (
          <div key={item.id} className='space-y-2 mt-4'>
            <div className='flex flex-row space-x-4'>
              <Avatar className='ring-[1px] ring-muted-foreground' size='xs'>
                <AvatarImage src={item.logo.url!} />
                <AvatarFallback className='text-xs'></AvatarFallback>
              </Avatar>
              <Badge onClick={() => redirect(item.link)}>
                <Link href={item.link}>{item.name} </Link>
              </Badge>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const CompanyLinks = () => {
  const [search, setSearch] = useState<string>('');

  const query = useQuery({
    queryKey: [search],
    queryFn: (ctx) => {
      const [search] = ctx.queryKey;
      return listCompanyLink({
        query: {
          name: search,
        },
      });
    },
  });

  const debouncedSearchFilter = useCallback(
    debounce((ev: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(ev.target.value);
    }, 500),
    [],
  );

  return (
    <div>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant={'ghost'} className={cn('relative')}>
            <IconBolt />
          </Button>
        </PopoverTrigger>
        <PopoverContent align='end' side='bottom' className='w-[20rem]'>
          <div className='flex flex-row space-x-2 items-center justify-between'>
            <DataTableTitle className='text-md'>Links</DataTableTitle>
            <Input
              size={'sm'}
              onChange={debouncedSearchFilter}
              placeholder='Filtrar por nombre'
            />
          </div>
          {query.data && <CompanyLinkList items={query.data.data} />}
        </PopoverContent>
      </Popover>
    </div>
  );
};
