import { httpClient } from '@/http/client';
import {
  ListCompanyInvitationsRequestQuery,
  ListCompanyInvitationsResponseBody,
  ListNotificationsRequestQuery,
  ListNotificationsResponseBody,
} from 'bff';
import qs from 'qs';

export const listNotifications = (
  query: ListNotificationsRequestQuery,
  cookie?: string,
): Promise<ListNotificationsResponseBody> =>
  httpClient
    .headers(
      cookie
        ? {
            cookie,
          }
        : {},
    )
    .query(qs.stringify(query))
    .get(`/v1/notification/list`)
    .json();
