'use client';

import { AuthInfoResponseBody } from 'bff';
import { CustomerDashboardLayout } from 'shared-components';
import { logoutUser } from '../../lib/logoutUser';
import { DashboardLayout } from '../../components/DashboardLayout';

export const DashboardShell = ({
  user,
  children,
}: {
  user: AuthInfoResponseBody;
  children: React.ReactNode;
}) => {
  if (user.type === 'inhouse_lawyer')
    return (
      <CustomerDashboardLayout logout={logoutUser}>
        {children}
      </CustomerDashboardLayout>
    );

  return <DashboardLayout>{children}</DashboardLayout>;
};
