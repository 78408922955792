import { httpClient } from '@/http/client';
import { AuthInfoResponseBody } from 'bff';
import { UserType } from 'database';

export const fetchUser = async (
  cookie?: string,
): Promise<AuthInfoResponseBody> =>
  httpClient
    .headers(
      cookie
        ? {
            cookie,
          }
        : {},
    )
    .get('/v1/auth/me')
    .json();
