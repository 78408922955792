'use client';

import { StylesConfig } from 'database';
import React, { useContext } from 'react';

export interface ThemeSpecs
  extends Omit<StylesConfig, 'company_id' | 'id' | 'logo_id' | 'scope'> {}

export interface ThemeContext extends ThemeSpecs {
  chartBorderRadius: number;
  logo?: {
    url?: string;
  };
}

const ThemeContext = React.createContext<ThemeContext | undefined>(undefined);

export const useTheme = () => useContext(ThemeContext)!;

const convertRemToPixels = (rem: number) => {
  if (typeof window !== 'undefined')
    return (
      rem *
      parseFloat(window.getComputedStyle(document.documentElement).fontSize)
    );

  return 0;
};

export const ThemeProvider = ({
  children,
  theme,
}: {
  children: React.ReactNode;
  theme: ThemeSpecs;
}) => {
  return (
    <ThemeContext.Provider
      value={{
        ...theme,
        chartBorderRadius: convertRemToPixels(theme.border_radius / 100),
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
